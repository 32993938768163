import axios from "axios";
import { Notification } from "element-ui";
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "";
}
//  else {
//   axios.defaults.baseURL = "https://medical.coolcou.com";
// }
axios.defaults.timeout = 50000;

//添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    Notification({
      title: error.message || "未知错误",
      type: "error",
    });
    return Promise.reject(error);
  }
);

//添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    console.log("error", error.config.url);
    if (error.response && error.response.status == 401 && error.config.url != "/api/resetPassWord") {
      // window.location.href = window.location.origin + "/Login";
    }
    Notification({
      title: '错误提示',
      message: error.message || '未知错误',
      type: 'error',
    });
    return Promise.reject(error); //返回错误信息
  }
);
export default axios;
