import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import axios from "./service/axios";
import ElementUI from "element-ui";
import "./css/common.scss"; //全局公共样式
import "./css/theme/index.css"; //自定义element主题色
import "element-ui/lib/theme-chalk/index.css";
var dayjs = require("dayjs");
// import dayjs from 'dayjs' // ES 2015
Vue.prototype.dayjs = dayjs;
Vue.prototype.axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
// const router = createRouter({ ... })

router.beforeEach(async (to, from, next) => {
  // if (
  //   // 检查用户是否已登录
  //   !isAuthenticated &&
  //   // ❗️ 避免无限重定向
  //   to.name !== 'Login'
  // ) {
  //   // 将用户重定向到登录页面
  //   return { name: 'Login' }
  // }
  if (to.meta.isLogin == true) {
    console.log(store.state)
    store.commit('initUser')
    if(store.state.userInfo==null) {
      next('/Login')
    } else {
      next()
    }
  } else {
    next()
  }
  // return false
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
