import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // userInfo: {account: '', password: '', token: ''}
    userInfo: {} || JSON.parse(localStorage.getItem("userInfo")),
  },
  getters: {},
  mutations: {
    initUser(state) {
      state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    },
    // 登录设置用户信息
    setUser(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    // 退出清空用户信息
    removeUser(state) {
      state.userInfo = { account: "", password: "", token: "" };
    },
  },
  actions: {},
  modules: {},
});
