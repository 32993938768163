import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/home/Page"),
    children: [
      {
        path: "/", // 显示Home页面
        name: "Home",
        component: () => import("@/views/home/Home.vue"),
        meta: { keepAlive: true }
      },
      {
        path: "/RecentRead", // 显示最近阅读
        name: "RecentRead",
        component: () => import("@/views/home/RecentRead.vue"),
        meta: { keepAlive: true }
      },
      {
        path: "/Recommendation", // 显示推荐阅读
        name: "Recommendation",
        component: () => import("@/views/home/Recommendation.vue"),
        meta: { keepAlive: true }
      },
      {
        path: "/home/DirectSearch", // 直接搜索页
        name: "DirectSearch",
        component: () => import("@/views/home/DirectSearch.vue"),
        meta: { keepAlive: true }
      },
      {
        path: "/home/IndirectSearch", // 间接搜索页
        name: "IndirectSearch",
        component: () => import("@/views/home/IndirectSearch.vue"),
        meta: { keepAlive: true }
      },
      {
        path: "/home/PaperSearch", // 检索输出页
        name: "PaperSearch",
        component: () => import("@/views/home/PaperSearch.vue"),
        meta: { keepAlive: true }
      },
      {
        path: "/paper/PaperDetails", // 论文详情页
        name: "PaperDetails",
        component: () => import("@/views/paper/PaperDetails.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/paper/pdf-viewer", // PDF查看器页面
        name: "PdfViewer",
        component: () => import("@/views/paper/PdfViewer.vue"),
        props: route => ({ pdfUrl: route.query.pdfUrl }),
        meta: { keepAlive: false }
      },
      {
        path: "/person",
        component: () => import("@/views/person/Person.vue"),
        meta: {
          isLogin: true  // 需要登录才能跳转的页面
        },
        children: [
          {
            path: "QADetail", // QA
            name: "QADetail",
            component: () => import("@/views/person/QADetail.vue"),
            meta: {
              isLogin: true  // 需要登录才能跳转的页面
            }
          },
          // 在这里添加更多 person 目录下的子路由
        ]
      }
    ],
  },
  {
    path: "/Pdf",
    component: () => import("@/views/help/Pdf.vue"),
  },
  {
    path: '/Login',
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: '/Register',
    component: () => import("@/views/login/Register.vue"),
  },
  {
    path: '/Forget',
    component: () => import("@/views/login/Forget.vue"),
  },
  {
    path: '/conformForget',
    component: () => import("@/views/login/ConformForget.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 重写vue-router重复点击报错
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
